<template>
  <div>
    <v-card>
      <div>
        <v-card-title v-if="!isSolding" class="headline accent">
          Забронировано
        </v-card-title>
        <v-card-title v-else>
          <loading-text :text="textToShow" class="text-h5"></loading-text>
          <v-progress-linear indeterminate color="yellow darken-2"></v-progress-linear>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col>
                <v-row class="text-h4 font-weight-bold mt-10">Товары</v-row>
                <v-row>
                  <v-divider></v-divider>
                </v-row>
                <v-row class="text-h6 mt-5">
                  <v-col cols="5"> Билеты x {{ ticketsCount }}</v-col>
                  <v-col class="text-right text-h6 font-weight-bold">
                    {{ (value.cost.total - value.cost.shadowTax) + " Руб" }}
                  </v-col>
                </v-row>
                <v-row>
                  <v-divider></v-divider>
                </v-row>
                <v-row class="text-h4 font-weight-bold mt-5">
                  <v-col cols="4">Итог</v-col>
                  <v-col align="end">{{ (value.cost.total - value.cost.shadowTax) + " Руб" }}</v-col>
                </v-row>

                <v-row justify="end" class="mt-10">
                  <v-spacer></v-spacer>
                  <v-btn text large color="orange" :disabled="isSolding" @click="cancel()">
                    Отмена
                  </v-btn>
                  <v-btn dark elevation="5" :disabled="isSolding" :loading="isSolding" color="orange" large
                    @click="payment">
                    Оплатить
                  </v-btn>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </div>
    </v-card>
  </div>
</template>

<script>
import OverlayLoader from "@/components/UI/OverlayLoader.vue";
import LoadingText from "@/components/UI/LoadingText.vue";
export default {
  name: "PurchaseSummary",
  components: { OverlayLoader, LoadingText },
  props: {
    value: {
      type: Object,
      require: true,
    },
    ticketsCount: {
      type: Number,
      require: true,
    },
  },
  data() {
    return {
      confirmLoading: false,
      isSolding: false,
      textToShow: "",
      buyStep: 0,
    };
  },
  methods: {
    cancel() {
      this.$emit("cancel", false);
    },
    async payment() {
      try {
        this.isSolding = true;
        this.textToShow = "Идет оформление билета";
        console.warn("Отправка 1");
        let resp1 = await this.$axios.post(
          this.$enums.Endpoints.Order.Buy + `?data=${this.value.workId}`
        );
        // this.textToShow = "Получение машрут-квитанции";
        // console.warn("Отправка 2");
        // let resp2 = await this.$axios.get(
        //   this.$enums.Endpoints.Data.Pdf + `?data=${resp1.data.workId}`
        // );
      } finally {
        this.isSolding = false;
      }
      await this.$router.push("/solded-tickets");
    },
    async SendTicketToPassenger() {
      let req = {
        query: {
          Pult: this.info.pult,
          itinReceipt: {
            regnum: this.info.regnum,
            surname: this.info.surname,
          },
        },
      };
      try {
        let resp = await this.$axios.post(`/Receipt/SendTicket`, req);
        return resp.data;
      } catch (e) {
        console.log("SendTicketToPassenger - Error");
        this.$store.state.error = {
          show: true,
          value: {
            Code: e.response.status,
            Message: e.response.data.Message,
          },
        };
        return null;
      }
    },
  },
};
</script>

<style></style>
